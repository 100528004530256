import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ImageOr = (props) => {

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "280px",
        maxHeight: "544px",
      }}
    >
      {props.image ? (
        <img
          src={props.image}
          alt={props.name}
          style={{ width: "100%", height: "400px", objectFit: "cover" }}
        />
      ) : (
        <Typography variant="h1" component="span" color="primary">
          {props.index + 1}
        </Typography>
      )}
    </Box>
  );
};

const Text = (props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h3" color="primary">
        {props.name}
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="primary.light"
        fontWeight={400}
      >
        {props.description}
      </Typography>
    </Stack>
  );
};

const SideBySideTile = (props) => {
  const borderRadius =
    props.border === "pill" ? 4 : props.border === "rounded" ? 2 : 0;
    const InnerBorderRadius =
    props.border === "pill" ? 2 : props.border === "rounded" ? 1 : 0;

  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "grid" },
          gridTemplateColumns: "repeat(2, 1fr)",
          mb: 4,
          p: 4,
          borderRadius: borderRadius,
          backgroundColor: "background.tiles",
          columnGap: 3,
          rowGap: 1,
          boxShadow: `0 3px 24px rgb(0 0 0 / 0.02)`,
        }}
      >
        {props.index % 2 === 0 ? (
          <>
            <Box borderRadius={InnerBorderRadius} overflow="hidden">
            <ImageOr
              image={props.image}
              name={props.name}
              index={props.index}
            />
            </Box>
            <Text name={props.name} description={props.description} />
          </>
        ) : (
          <>
            <Text name={props.name} description={props.description} />
            <Box borderRadius={InnerBorderRadius} overflow="hidden">
            <ImageOr
              image={props.image}
              name={props.name}
              index={props.index}
            />
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: { xs: "grid", sm: "none" },
          gridTemplateColumns: "repeat(1, 1fr)",
          mb: 4,
          p: 4,
          borderRadius: borderRadius,
          backgroundColor: "background.tiles",
          columnGap: 3,
          rowGap: 1,
        }}
      >
        <ImageOr image={props.image} name={props.name} index={props.index} />
        <Text name={props.name} description={props.description} />
      </Box>
    </>
  );
};

export default SideBySideTile;
