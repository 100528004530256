import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const SmallCard = (props) => {

  const borderRadius = props.border === "pill" ? 4 : props.border === "rounded" ? 2 : 0

  return (
    <Grid item xs={12} sm={6} md={props.itemSize}>
      <Box sx={{borderRadius: borderRadius, overflow: 'hidden', backgroundColor: "background.tiles"}}>
      
      {props.image ? (
            <>
                <img src={props.image} alt={props.name} style={{width:'100%', height: '300px', objectFit:"cover"}}/>
              
            </>
          ) : null}
      <Stack
        sx={{
          padding: 4,
          minHeight: "132px",
          alignItems: "left",
          boxShadow: `0 3px 24px rgb(0 0 0 / 0.02)`
        }}
      >
        
        <Box>
          
          <Typography variant="h4" component="h3">
            {props.name}
          </Typography>
          {props.price ? (
            <Typography variant="h6" fontWeight="bold" color="primary.light">
              {props.price}
            </Typography>
          ) : null}
        </Box>
        <Typography variant="body1" component="p" fontWeight={400} color="primary.light">
          {props.description}
        </Typography>
      </Stack>
      </Box>
    </Grid>
  );
};

export default SmallCard;
