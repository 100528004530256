import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LargeTile from "../atoms/LargeTile";
import SmallTile from "../atoms/SmallTile";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const Products = (props) => {
  const items = props.products?.length;
  const density = props.layout.density;
  const gridSize = items === 1 ? 12 : items === 2 ? 6 : 4;

  return (
    <>
      {items !== 0 ? (
        <>
          <Stack textAlign="center" py={4}>
            {props.layout.sectionTitles && (
              <Typography variant="h2" color="primary.light">
                Products
              </Typography>
            )}
          </Stack>
          {density === "compact" && items > 1 ? (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3} display="flex">
                {props.products.map((product, index) => {
                  return (
                    <SmallTile
                      key={"product-" + index}
                      name={product.name}
                      description={product.description}
                      price={product.price}
                      image={props.images[index]?.node?.fluid?.src}
                      itemSize={gridSize}
                      border={props.layout.buttonShape}
                    />
                  );
                })}
              </Grid>
            </Box>
          ) : (
            props.products.map((product, index) => {
              return (
                <LargeTile
                  key={"product-" + index}
                  name={product.name}
                  description={product.description}
                  price={product.price}
                  image={props.images[index]?.node?.fluid?.src}
                  border={props.layout.buttonShape}
                />
              );
            })
          )}
        </>
      ) : undefined}
    </>
  );
};

export default Products;