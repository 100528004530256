import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LargeTile from "../atoms/LargeTile";
import SmallTile from "../atoms/SmallTile";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const Solutions = (props) => {
  const items = props.solutions?.length;
  const density = props.layout.density;
  const gridSize = items === 1 ? 12 : items === 2 ? 6 : 4;

  return (
    <>
      {items !== 0 ? (
        <>
          <Stack textAlign="center" py={4}>
            {props.layout.sectionTitles && (
              <Typography variant="h2" color="primary.light">
                Solutions
              </Typography>
            )}
          </Stack>
          {density === "compact" && items > 1 ? (
            <Box sx={{ flexGrow: 1 }} >
              <Grid container spacing={3} display="flex">
                {props.solutions.map((solution, index) => {
                  return (
                    <SmallTile
                      key={"solution-" + index}
                      name={solution.name}
                      description={solution.description}
                      price={solution.price}
                      image={props.images[index]?.node?.fluid?.src}
                      itemSize={gridSize}
                      border={props.layout.buttonShape}
                    />
                  );
                })}
              </Grid>
            </Box>
          ) : (
            props.solutions.map((solution, index) => {
              return (
                <LargeTile
                  key={"solution-" + index}
                  name={solution.name}
                  description={solution.description}
                  price={solution.price}
                  image={props.images[index]?.node?.fluid?.src}
                  border={props.layout.buttonShape}
                />
              );
            })
          )}
        </>
      ) : undefined}
    </>
  );
};

export default Solutions;