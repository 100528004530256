import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const SmallGridTile = (props) => {
  return (
    <Grid item xs={6} md={3}>
      <Stack
        sx={{
          padding: 4,
          minHeight: "132px",
          justifyContent: "center"
        }}
        spacing={1}
      >
        <Box>
          {props.image ? (
            <>
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              ></Box>
              <Stack alignItems="center">
                <img src={props.image} alt={props.name} width="120px" />
              </Stack>
            </>
          ) : null}
          {props.name ? (<Typography variant="h4" component="h3">
            {props.name}
          </Typography>) : null}
          {props.price ? (
            <Typography variant="h6" fontWeight="bold" color="primary.light">
              {props.price}
            </Typography>
          ) : null}
        </Box>
        {props.typography ? <Typography variant="body1" component="p" fontWeight={400} color="primary.light">
          {props.description}
        </Typography> : null}
      </Stack>
    </Grid>
  );
};

export default SmallGridTile;
